<template>
  <publicExport :exportTypeList="exportTypeList"></publicExport>
</template>
<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import publicExport from "@/components/common/publicExport";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "exportTask",
  data() {
    return {
      exportTypeList: [
        {value: 'productExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000667')},
        {value: 'inventoryExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000697')},
        {value: 'inventoryLogExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005530')},
        {value: 'packageExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002459')},
        {value: 'ymsInventoryExport', name: `${setConfigCharacters}${aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005529')}`},
        {value: 'supplierBillExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005531')},
        {value: 'wmsPickupOrderExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005532')},
        {value: 'packageInfoOrderExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001322')},
        {value: 'packageInfoStockExport', name: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1001219')},
      ]
    };
  },
  components: {
    publicExport
  }
};
</script>

<style scoped>

</style>
